<template>
    <!-- 我的客户 -->
    <div class="customer_box">
        <div class="customer_search_box" style="position: static;">

            <div class="search-item">
                <span>考核月份</span>
                <el-select style="width: 300px;" multiple v-model="months" clearable placeholder="请选择">
                    <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <div class="btn_box">
                <el-button class="btn_item" type="primary" size="small" @click="searchClick">查询</el-button>
                <el-button class="btn_item" size="small" @click="resetClick">重置</el-button>
            </div>
        </div>
        <div>
            <div class="alert"><i style="color:#078fea;margin-right:10px;" class="el-icon-warning"></i><span>报表的所有统计数据均根据用户所选的考核月份而变化，按照仓库实际发货时间和数量计算。数据每日凌晨0点更新一次。</span></div>
        </div>
        <el-tabs v-if="$store.state.busyMemberInfo.authTitle=='省区账号'" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="重点产品维度" name="first"></el-tab-pane>
            <el-tab-pane label="客户经理维度" name="second"></el-tab-pane>
        </el-tabs>
        <div class="table_mode">
            <el-table ref="expandTable" v-if="tableData.length" :data="tableData" @cell-click="cellClick" :cell-style="cellStyle" show-summary :summary-method="getSummaries" v-loading="loading" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}" stripe style="width: 100%">
                <el-table-column prop="title" label="大区-省区" min-width="200" :show-overflow-tooltip="true" fixed align="center">
                    <template slot-scope="scope">
                        <span style="color:#078fea;cursor:pointer;" @click="goExpanded(scope.row)">{{scope.row.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="teamNum" label="团队人数" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="target" label="考核指标（元）" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="noSaleNum" label="挂零人数" min-width="130" :show-overflow-tooltip="true" align="center">
                </el-table-column>
                <el-table-column prop="sale" label="发货金额（元）" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="diff" label="指标差额（元）" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
                <el-table-column prop="schedule" label="销售进度" min-width="130" :show-overflow-tooltip="true" align="center"></el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
            <div v-else>
                <el-table v-if="activeName=='first'" :data="tableData1" v-loading="loading" show-summary :summary-method="getSummaries1" stripe style="width: 100%">
                    <el-table-column prop="productName" label="商品名称" min-width="200" :show-overflow-tooltip="true" align="center" fixed></el-table-column>
                    <el-table-column prop="productCode" label="商品编码" min-width="120" :show-overflow-tooltip="true" align="center" fixed></el-table-column>
                    <el-table-column prop="norms" label="规格" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="produceUnit" label="厂家" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="price" label="统一供货价" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="saleNum" label="销售数量" min-width="90" :show-overflow-tooltip="true" align="center">
                        <template slot="header" slot-scope="scope">
                            <div>销售数量
                                <el-tooltip class="item" effect="dark" :content="'该数据统计当前省办所有客户经理的销售数量'" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sale" label="销售金额（元）" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <div slot="empty" class="nodata_box">
                        <img src="@/assets/images/index/nodata.png" />
                        <span class="txt">暂无数据哦~</span>
                    </div>
                </el-table>
                <el-table v-if="activeName=='second'" :data="tableData1" ref="saleTable" v-loading="loading" show-summary :summary-method="getSummaries2" style="max-height:1200px;  overflow-y: auto;">
                    <el-table-column prop="name" label="客户经理姓名" min-width="100" :show-overflow-tooltip="true" align="center" fixed></el-table-column>
                    <el-table-column prop="saleNum" label="销售数量" min-width="90" :show-overflow-tooltip="true" align="center">
                        <template slot="header" slot-scope="scope">
                            <div>销售数量
                                <el-tooltip class="item" effect="dark" :content="'该数据统计当前省办所有客户经理的销售数量'" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sale" label="销售金额（元）" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <div slot="empty" class="nodata_box">
                        <img src="@/assets/images/index/nodata.png" />
                        <span class="txt">暂无数据哦~</span>
                    </div>
                </el-table>
            </div>
        </div>
        <!-- <div class="pagination_box">
            <el-pagination background layout="prev, pager, next, sizes, total" :page-sizes="[10, 20, 30, 40]" :page-size.sync="filterinfo.pageSize" :current-page.sync="filterinfo.page" :total="total" @current-change="getCustomerList" @size-change="getCustomerList(1)">
            </el-pagination>
        </div> -->
        <el-dialog :title="saleTitle" :visible.sync="tableVisible">
            <el-tabs v-model="activeName1" @tab-click="handleClick1">
                <el-tab-pane label="重点产品维度" name="first"></el-tab-pane>
                <el-tab-pane label="客户经理维度" name="second"></el-tab-pane>
            </el-tabs>
            <el-table v-if="activeName1=='first'" :data="tableData1" ref="saleTable" v-loading="loading" show-summary :summary-method="getSummaries1" style="max-height:1200px;  overflow-y: auto;">
                <el-table-column prop="productName" label="商品名称" min-width="200" :show-overflow-tooltip="true" align="center" fixed></el-table-column>
                    <el-table-column prop="productCode" label="商品编码" min-width="120" :show-overflow-tooltip="true" align="center" fixed></el-table-column>
                    <el-table-column prop="norms" label="规格" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="produceUnit" label="厂家" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="price" label="统一供货价" min-width="90" :show-overflow-tooltip="true" align="center"></el-table-column>
                    <el-table-column prop="saleNum" label="销售数量" min-width="90" :show-overflow-tooltip="true" align="center">
                        <template slot="header" slot-scope="scope">
                            <div>销售数量
                                <el-tooltip class="item" effect="dark" :content="authTitle=='客户经理'?'该数据仅统计当前登录人的销售数量':'该数据统计当前省办所有客户经理的销售数量'" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sale" label="销售金额（元）" min-width="120" :show-overflow-tooltip="true" align="center"></el-table-column>

                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
            <el-table v-if="activeName1=='second'" :data="tableData1" v-loading="loading" ref="saleTable" show-summary :summary-method="getSummaries2" style="max-height:1200px;  overflow-y: auto;">
                <el-table-column prop="name" label="客户经理姓名" min-width="100" :show-overflow-tooltip="true" align="center" fixed></el-table-column>
                <el-table-column prop="saleNum" label="销售数量" min-width="90" :show-overflow-tooltip="true" align="center">
                        <template slot="header" slot-scope="scope">
                            <div>销售数量
                                <el-tooltip class="item" effect="dark" :content="'该数据统计当前省办所有客户经理的销售数量'" placement="top">
                                    <i class="el-icon-warning-outline"></i>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="sale" label="销售金额（元）" min-width="80" :show-overflow-tooltip="true" align="center"></el-table-column>
                <div slot="empty" class="nodata_box">
                    <img src="@/assets/images/index/nodata.png" />
                    <span class="txt">暂无数据哦~</span>
                </div>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
import { handlerTableData } from '@/utils/common'
export default {
    name: 'MyCustomer',
    data() {
        return {
            loading: false,
            total: 0,
            provinceOptions: [],
            cityOptions: [],
            areaOptions: [],
            // filterinfo: {
            //     orderBy: '',
            //     page: 1,
            //     pageSize: 10,
            //     Click: '',
            //     shopType: undefined,
            //     attr: undefined,
            // },
            tableData: [{
                id: 1,
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                id: 2,
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                id: 3,
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄',
                children: [{
                    id: 31,
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    id: 32,
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }]
            }, {
                id: 4,
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }],
            tableData1: [],
            // tableData: [],
            attributeOptions: [],
            months: [],
            options: [
                {
                    id: 1,
                    title: '1-2月',
                },
                {
                    id: 3,
                    title: '3月',
                },
                {
                    id: 4,
                    title: '4月',
                },
                {
                    id: 5,
                    title: '5月',
                },
                {
                    id: 6,
                    title: '6月',
                }
            ],
            roleNumber: localStorage.getItem('roleNumber'),
            tableVisible: false,
            flag: false,
            summary: {},
            summary1: {},
            saleTitle: '',
            activeName: 'first',
            activeName1: 'first',
            authTitle: '',
            departId: '',
        };
    },
    created() {

    },
    mounted() {
        this.getSaleList()
        //   this.getSaleDetail(0)
        console.log(this.roleNumber);

    },
    methods: {
        cellClick(row, column) {
            this.saleTitle = row.title + '重点产品销售进度'
            this.departId= row.id
            const currentDate = new Date();
                var months = [currentDate.getMonth() + 1]; // 返回1-12
            if (this.months.length) {
                 months = JSON.parse(JSON.stringify(this.months))
            }
            if (this.months.includes(1)) {
                months.push(2)
            }
            if (column.label.includes('省区') && !row.title.includes('区')) {
                console.log(row, column);
               
                if (this.activeName1 == 'first') {
                    this.getSaleDetail(row.id, months)
                } else {
                    this.getSaleManage(row.id, months)
                }

            }


        },
        cellStyle({ row, column, rowIndex, columnIndex }) {
            if (column.label.includes('省区')) {
                // 给所点击的 单元格添加样式
                return "cursor:pointer;color: #389DFF"

            }
        },
        goExpanded(i) {
            this.flag = !this.flag;
            this.$refs.expandTable.toggleRowExpansion(i, this.flag);

        },
        handleClick(tab, event) {
            //   console.log(tab, event);
            this.tableData = []
            if (this.months.length) {
                var months = JSON.parse(JSON.stringify(this.months))
            }
            if (this.months.includes(1)) {
                months.push(2)
            }
            this.getSaleList(months)
        },
        handleClick1(tab, event) {
            const currentDate = new Date();
            var months = [currentDate.getMonth() + 1]; // 返回1-12
            // this.tableData = []
            if (this.months.length) {
                 months = JSON.parse(JSON.stringify(this.months))
            }
            if (this.months.includes(1)) {
                months.push(2)
            }
            if (this.activeName1 == 'first') {
                            this.getSaleDetail(this.departId, months)
                        } else {
                            this.getSaleManage(this.departId, months)
                        }
        },
        resetClick() {
            this.months = [];
            this.getSaleList()
        },
        searchClick() {
            if (this.months.length) {
                var months = JSON.parse(JSON.stringify(this.months))
            }
            if (this.months.includes(1)) {
                months.push(2)
            }
            this.getSaleList(months)
        },
        getSummaries(param) {
            const sums = [];
            sums[0] = this.summary.title;
            sums[1] = this.summary.teamNum;
            sums[2] = this.summary.target;
            sums[3] = this.summary.noSaleNum;
            sums[4] = this.summary.sale;
            sums[5] = this.summary.diff;
            sums[6] = this.summary.schedule;

            return sums;
        },
        getSummaries1(param) {
            const sums = [];
        
                sums[0] = this.summary1.productName;
            // sums[4] = this.summary1.reward;
            // sums[5] = this.summary1.target;
            // sums[4] = this.summary1.price;
            sums[5] = this.summary1.saleNum;
            sums[6] = this.summary1.sale;
           
            // this.$nextTick(() => {
            //       // myTable是表格的ref属性值
            //       if (this.$refs.saleTable && this.$refs.saleTable.doLayout) {
            //         this.$refs.saleTable.doLayout();
            //       }
            //     });
            return sums;
        },
        getSummaries2(param) {
            const sums = [];
       
                sums[0] = this.summary1.name;
                sums[1] = this.summary1.saleNum;
                sums[2] = this.summary1.sale;
          
            // this.$nextTick(() => {
            //       // myTable是表格的ref属性值
            //       if (this.$refs.saleTable && this.$refs.saleTable.doLayout) {
            //         this.$refs.saleTable.doLayout();
            //       }
            //     });
            return sums;
        },
        // 获取我的客户列表
        getSaleList(months) {
            if (!months) {
                const currentDate = new Date();
                var months = [currentDate.getMonth() + 1]; // 返回1-12
            }
            this.loading = true
            this.$api.saleNumList({ months }).then((res) => {
                this.loading = false
                if (res.data.code == 200) {
                    if (res.data.msg === 'OK') {
                        this.tableData = handlerTableData(res.data.data.keynoteSchedule)
                        console.log(this.tableData);
                        this.summary = res.data.data.summary
                    }
                    else if (res.data.msg == 'goToNext') {
                        this.tableData = []
                        if (this.activeName == 'first') {
                            this.getSaleDetail(0, months)
                        } else {
                            this.getSaleManage(0, months)
                        }
                    }
                }
            });
        },
        getSaleDetail(departId, months) {
            this.loading = true
            this.$api.saleNumDetail({ departId, months }).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData1 = handlerTableData(res.data.data.keynoteScheduleDetail)
                    console.log(this.tableData1);
                    this.summary1 = res.data.data.summary
                    // this.saleTitle = res.data.data.title + '重点产品销售进度'
                    // this.authTitle = res.data.data.authTitle
                }
                if (departId) {
                    this.tableVisible = true
                }
            });
        },
        getSaleManage(departId, months) {
            this.loading = true
            this.$api.saleNumManage({ departId, months }).then((res) => {
                this.loading = false
                if (res.data.code === 200) {
                    this.tableData1 = handlerTableData(res.data.data.keynoteScheduleDetail)
                    console.log(this.tableData1);
                    this.summary1 = res.data.data.summary
                    // this.saleTitle = res.data.data.title + '重点产品销售进度'
                    // this.authTitle = res.data.data.authTitle

                }
                if (departId) {
                    this.tableVisible = true
                }
            });
        },

    }
};
</script>
<style lang="scss" scoped>
.customer_box {
    position: relative;
}
.el-form-item__label {
    text-align: left !important;
}
.alert {
    display: flex;
    align-items: center;
    margin-top: 10px;
    // width: 690px;
    // margin-left: 20px;
    // justify-content: center;
    border: 1px solid #fff3c6;
    font-size: 16px;
    background: #e6f7ff;
    padding: 5px;
    margin-bottom: 10px;
    color: #666;
    border: 1px #bae7ff solid;
}
</style>
<style lang="scss" >
.self-dialog {
    width: 500px;
    border-radius: 10px !important;
    .el-form-item__content {
        display: flex !important;
    }
}
.el-dialog {
    margin-top: 10vh !important;
}
</style>